import React from 'react';

import CashAdvanceIcon from '../../../../images/CashAdvance';
import CreditCardsIcon from '../../../../images/CreditCards';
import DebtIcon from '../../../../images/Debt';
import EarnMoneyIcon from '../../../../images/EarnMoney';
import InsuranceIcon from '../../../../images/Insurance';
import LoansIcon from '../../../../images/Loans';
import SaveMoneyIcon from '../../../../images/SaveMoney';

import style from './Hero.scss';

export const list = [
  {
    href: '/cash-advance/',
    icon: <CashAdvanceIcon />,
    title: 'Cash Advance',
    target: undefined,
  },
  {
    href: 'https://loans.thinksaveretire.com/',
    icon: <LoansIcon />,
    title: 'Loans',
    target: '_blank',
  },
  {
    href: 'https://insurance.thinksaveretire.com/',
    icon: <InsuranceIcon />,
    title: 'Insurance',
    target: '_blank',
  },
  {
    href: '/extra-money/',
    icon: <EarnMoneyIcon />,
    title: 'Earn Money',
    target: undefined,
  },
  {
    href:
      'http://g.gituy.com/cgi/r?;n=203;c=1626483;s=7273;x=7936;f=201611071631130;u=j;z=TIMESTAMP;src=693887',
    icon: <CreditCardsIcon />,
    title: 'Credit Cards',
    target: '_blank',
  },
  {
    href: '/debt-offers/',
    icon: <DebtIcon />,
    title: 'Debt',
    target: undefined,
  },
  {
    href: '/save/',
    icon: <SaveMoneyIcon />,
    title: 'Save Money',
    target: undefined,
  },
];

const HeroA = () => {
  return (
    <section className={style.hero}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1>
              Navigate life on your terms
              <small>Compare rates and get expert guidance on all things finance</small>
            </h1>
            <div className={style.items}>
              {list.map((item) => (
                <a key={item.href} href={item.href} target={item.target}>
                  {item.icon}
                  <h4>{item.title}</h4>
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroA;
